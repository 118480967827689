<template>
  <div class="menu-body" v-if="$oidc.isAuthenticated">
    <Loader v-if="showLoader" />
    <div style="display: flex">
      <div class="col-md-8 col-lg-8 col-sm-8">
        <Breadcrumb />
      </div>
      <div class="col-md-4 col-lg-4 col-sm-4" style="justify-content: flex-end; text-align: right; margin-top: 20px">
        <b-button pill class="doc-bt po-class" size="lg" style="justify-content: flex-end" @click="poDocument()">
          PO Reference Documents
        </b-button>
      </div>
    </div>
    <div class="main-con">
      <div style="padding-left: 20px; padding-top: 20px">
        <div style="display: flex">
          <div class="col-md-8 col-lg-8 col-sm-8">
            <div style="display: flex">
              <span class="card-title card-title-cl card-main-tl" style="color: #234372">
                My Learning Assignments</span>
            </div>
            <div class="table-header d-flex" style="padding: 0px">
              <div style="display: flex; padding: 0px">
                <div class="in-action card-tab-act" @click="course = 'Pending'" :class="{
                  'primary-color': course == 'Pending',
                }">
                  <div :class="{
                    'primary-color': course == 'Pending',
                  }">
                    Pending
                  </div>
                  <hr :class="{ 'active-tab-hr': course == 'Pending' }" />
                </div>
                <div class="in-action card-tab-act" @click="course = 'Completed'" :class="{
                  'primary-color': course == 'Completed',
                }">
                  <div :class="{
                    'primary-color': course == 'Completed',
                  }">
                    Completed
                  </div>
                  <hr :class="{ 'active-tab-hr': course == 'Completed' }" />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4 col-lg-4 col-sm-4" style="text-align: right; padding-bottom: 20px">
            <b-button pill class="primar-btn submit-btn" size="lg" style="justify-content: flex-end"
              @click="assignLearning()">
              Assign Learnings to self
            </b-button>
          </div>
        </div>
        <hr class="act-tab-dr" />
      </div>
      <div v-if="course == 'Pending'">
        <Education :dataArr="pendingCourseShow"> </Education>
      </div>
      <div v-if="course == 'Completed'">
        <Completedcourse :dataArr="compCourseShow"> </Completedcourse>
      </div>
      <div class="ft-12 primary-color see-all-detais ed-details-btn" style="text-align: center">
        <div @click="viewAllFunc()">
          <span class="view-class" v-if="
            (course == 'Pending' &&
              pendingCourseDetails.length > pendingCourseShow.length) ||
            (course == 'Completed' &&
              completeCourseDetails.length > compCourseShow.length)
          ">
            View All
            <img alt="view all" class="attch-icon" src="../../assets/Component 69 – 55.svg"
              style="height: 20px; width: 20px" />
          </span>
        </div>
      </div>
      <div v-if="
        (course == 'Completed' && completeCourseDetails.length == 0) ||
        (course == 'Pending' && pendingCourseDetails.length == 0)
      " class="no-data">
        <img alt="no data" src="../../assets/No_Data.svg" />
        <span class="ed-date">No Records Found</span>
      </div>
    </div>
    <div v-if="
  !(loggedinUserRole.length == 1 && loggedinUserRole.includes(roles.USER))
    ">
      <EducationMyTeam> </EducationMyTeam>
    </div>
  </div>
</template>
<script>
import Loader from "../../components/common/Loader.vue";
import "../../common/css/CommonStyle.css";
import "../../common/css/font-categories.css";
import Education from "./educationHome.vue";
import Breadcrumb from "../../components/common/Breadcrumb.vue";
import Api from "../../common/constants/api";
import ApiCalls from "../../common/api/apiCalls";
import EducationMyTeam from "./educationMyTeam.vue";
import Completedcourse from "./Completedcourse.vue";
import Role from "../../common/constants/roles";
export default {
  name: "EducationHome",
  data() {
    return {
      roles: Role,
      showLoader: false,
      course: "Pending",
      completeCourseDetails: [],
      courseDetails: [],
      numberOfRec: 4,
      pendingCourseDetails: [],
      numberOfRecPending: 4,
      numberOfRecCompleted: 4,
      compCourseShow: [],
      pendingCourseShow: [],
      loggedinUserRole: "",
      emailArr: [],
      completedCount: "",
      pendingCount: "",
      dataArray: [],
    };
  },
  components: {
    Breadcrumb,
    Education,
    Completedcourse,
    EducationMyTeam,
    Loader,
  },
  beforeCreate() {
    this.$store.dispatch("selectedMenu", {
      main: 7,
    });
  },
  created() {
    this.$store.dispatch("breadcrumb", {
      title: "Education",
      breadcrumb: [
        { name: "Home / ", primary: true, redirect: "/dashboard" },
        { name: "Education", primary: false },
      ],
    });
    this.loggedinUserRole = this.$oidc.userProfile.roles;
    this.courseDataForComplete();
    this.courseDataForPending();
  },
  methods: {
    viewAllFunc() {
      if (this.course == "Pending") {
        this.numberOfRecPending = this.pendingCourseShow.length + 4;
        this.pendingCourseShow = this.pendingCourseDetails.slice(
          0,
          this.numberOfRecPending
        );
      }
      if (this.course == "Completed") {
        this.numberOfRecCompleted = this.compCourseShow.length + 4;
        this.compCourseShow = this.completeCourseDetails.slice(
          0,
          this.numberOfRecCompleted
        );
      }
    },
    poDocument() {
      this.$router.push("/manage-po-documents");
    },
    assignLearning() {
      this.$router.push("/assign-learning-to-self");
    },
    courseDataForComplete() {
      this.showLoader = true;
      this.compCourseShow = [];
      this.completeCourseDetails = [];
      let url = Api.LEARNINGASSIGNMENT + 1000 + "?Type=completed";
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success) {
          this.completeCourseDetails = data.data.result;
          this.completeCourseDetails.forEach((element) => {
            element.title =
              element.title + " (" + element.componentId.split("_").pop() + ")";
            element.shortName = "" + element.componentId.split("_").pop() + "";
            if (element.completionDate)
              element.completionDate = new Date(element.completionDate);
          });
          this.compCourseShow = this.completeCourseDetails.slice(
            0,
            this.numberOfRecCompleted
          );
          this.showLoader = false;
        }
      });
    },

    courseDataForPending() {
      this.showLoader = true;
      this.pendingCourseShow = [];
      this.pendingCourseDetails = [];
      let url = Api.LEARNINGASSIGNMENT + 1000 + "?Type=pending";
      ApiCalls.httpCallDetails(url, "get").then((data) => {
        if (data.success) {
          this.pendingCourseDetails = data.data.result;
          this.pendingCourseDetails.forEach((element) => {
            element.title =
              element.title + " (" + element.componentId.split("_").pop() + ")";
            element.shortName = element.componentId.split("_").pop();
            if (element.requiredDate)
              element.requiredDate = element.requiredDate
                ? new Date(element.requiredDate)
                : null;
          });
          this.pendingCourseShow = this.pendingCourseDetails.slice(
            0,
            this.numberOfRecPending
          );
          this.showLoader = false;
        }
        this.showLoader = false;
      });
    },
  },
};
</script>

<style scoped>
.no-data {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
  height: 8rem;
  justify-content: space-evenly;
}

.po-class {
  height: 40px;
  color: #274ba7;
  font-size: 1.1666666666666667rem;
  line-height: 1.25rem;
  font-family: "BarlowM", sans-serif;
}

.doc-bt {
  border-color: #869ed8 !important;
  /* background: #eff3ff 0% 0% no-repeat padding-box !important; */
  background: transparent;
  color: #274ba7 !important;
  border-width: 1px !important;
  border-style: solid !important;
}

.doc-bt:hover {
  border-color: #869ed8 !important;
  color: #274ba7 !important;
  background: #cad0e4 0% 0% no-repeat padding-box !important;
}

.view-class {
  vertical-align: middle;
  font-size: 1.2rem;
  font-family: "BarlowM", sans-serif;
}

.submit-btn {
  height: 3rem;
  width: 16.9rem;
  font-family: "BarlowM", sans-serif;
  font-size: 1.166rem;
  padding: 0px;
}

.submit-btn:focus {
  background-color: #274ba7;
}

.ed-date {
  font-size: 1.166rem;
  font-family: "BarlowM", sans-serif;
}



.maturity-dd ul {
  width: 13rem !important;
}

.maturity-dd ul li {
  padding-left: 1.5rem !important;
}

.maturity-dd button {
  font-size: 1.33rem;
  font-family: "BarlowM", sans-serif;
}

.mm-menu {
  margin-right: 0rem;
}

#edit-li:disabled {
  cursor: not-allowed;
  opacity: 0.3;
}

#edit-li {
  width: 100%;
  text-align: left;
  border: none;
  background: none;
}

.action-item {
  padding: 0 !important;
  margin-top: 18px;
}

.card-title-cl {
  color: #234372;
  /* font-size: 0.875em;
  font-weight: 600; */
}

.training-assigned {
  color: #0e770a;
}

.trainig-image {
  margin-top: 16px;
}

.update-bt {
  background-color: #eff3ff !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
}

.update-bt:hover {
  background-color: #e3e8f7 !important;
  border-color: #96ace2 !important;
  color: #274ba7 !important;
}

#my-table th div {
  color: #313131;
}

#my-table tbody tr:nth-child(odd) {
  background-color: #fafafa !important;
}

#my-table tbody tr:nth-child(even):hover {
  background-color: #fff !important;
}

#my-table tr td:first-child {
  padding-left: 1.25em !important;
}

#my-table tr th:first-child {
  padding-left: 1.25em !important;
}

.action-item-card {
  padding: 0px !important;
}

.edu-card {
  padding-left: 1rem;
  padding-right: 1rem;
}

.action-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.my-header {
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.table-header {
  padding-left: 2rem;
  padding-right: 2rem;
  margin-top: 1rem;
  margin-bottom: 10px;
  /* font-weight: 600; */
}

.active-tab-hr {
  border-top: 3px #234372 solid;
  margin-top: 1rem;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
}

.act-tab-dr {
  margin-top: -2rem !important;
}

.in-action {
  margin-right: 1.688em;
}

.action-table tbody {
  font-size: 0.875em;
}

.per-page {
  width: 4rem;
  height: 2.5rem;
  border: 1px solid #e2dcdc;
}

.per-page-cont {
  text-align: right;
  position: absolute;
  left: 50%;
  top: 0rem;
}

.pagination-con {
  font-size: 0.875em;
  padding-left: 2.5rem;
  padding-right: 1.25rem;
}

.tema-learning {
  margin-top: 2.5rem;
  border-radius: 6px;
}

.tr-date {
  color: #434343;
  margin-top: 1rem;
}

.tr-require {
  margin-left: 5px;
}

.tr-name {
  color: #234bac;
  margin-top: 0.4rem;
}

.details-cl {
  margin-top: 0.4rem;
  margin-left: 2em;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cource-img {
  height: 100%;
}

.start-course-bt {
  background-color: #274ba7;
  border-color: #274ba7 !important;
  margin-top: 0.4rem;
}

.start-course-bt:hover {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.start-course-bt:active {
  background-color: #258cf3 !important;
  border-color: #258cf3 !important;
}

.start-course-bt:focus {
  box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

.nav-bt {
  margin-top: 1rem;
}

.nav-bt-div {
  width: 50%;
  align-items: center;
  cursor: pointer;
}

.tr-image {
  margin-top: 1rem;
}

.nav-mr {
  margin-right: 5px;
}

.nav-icons {
  background: #274ba7;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffff;
}

.team-learning-chart {
  width: 77%;
}

.view-team-learning {
  width: 23%;
  background-color: #f1fbff;
  text-align: center;
}

.pie-chart-con {
  width: 30%;
}

.team-learning-con {
  align-items: center;
  height: 12rem;
}

.pie-chart-con .chart__label {
  opacity: 0 !important;
}

.pie-chart-con .chart__line {
  opacity: 0 !important;
}

.bar-lables {
  align-items: center;
}

.completed-dot {
  background-color: #3bd761;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.bar-slice-info {
  text-align: center;
}

.slice-name-cp {
  color: #3bd761;
}

.slice-count {
  color: #606060;
}

.slice-name-due {
  color: #dc5656;
}

.slice-name-td {
  color: #e7dd09;
}

.due-dot {
  background-color: #dc5656;
}

.todo-dot {
  background-color: #e7dd09;
}

.next-view-ast {
  margin: auto;
}

.ast-img {
  width: 138px;
  margin-top: 4em;
}

.team-learninf-botm {
  border-bottom: 1px #f3f3f3 solid;
}

.view-more {
  width: 40%;
  text-align: center;
}

.go-action-card {
  margin-top: 4rem;
  background-color: #fff;
  border-radius: 6px;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

.po-action-items {
  border-top: 1px solid #e2e0e0;
  background-color: #fff;
  border-radius: 0 0 6px 6px;
  padding-top: 1.25rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1.25rem;
}

#bar-chart-tb th {
  display: none !important;
}

/* #bar-chart-tb tbody tr:nth-child(odd) {
    background-color: #FFF !important;
} */
.op-tabele-data {
  padding-left: 3rem;
  padding-right: 3rem;
}

#bar-chart-tb td {
  font-size: 0.8em !important;
  font-weight: 600;
  color: #313131;
}

#bar-chart-tb tr td:first-child {
  width: 1%;
}

.toggle-bt {
  background: none;
  border: none;
}

.toggle-bt:focus {
  color: #274ba7;
  background-color: #fff;
  border-color: #fff;
  box-shadow: 0 0 0 0 rgb(255 255 255 / 50%);
}

.toggle-bt:hover {
  color: #274ba7;
  background-color: #fff;
  border-color: #fff;
}

.toggle-bt:active {
  color: #274ba7 !important;
  background-color: white !important;
  border-color: white !important;
  box-shadow: 0 0 0 0 rgb(130 138 145 / 50%) !important;
}

#detail-table td {
  color: #313131;
  font-size: 0.875em !important;
}

#detail-table tr {
  color: #313131;
  font-size: 0.875em !important;
  background-color: #eff3ff !important;
}

#detail-table tr td:first-child {
  width: 17%;
  text-align: center !important;
}

#detail-table tr td {
  text-align: right;
}

.bar-chart-container {
  display: flex;
  align-items: center;
  border-bottom: 1px #eae5e5 solid;
  height: 3rem;
}

.bar-slices {
  width: 20%;
  color: #313131;
  text-align: center;
  word-break: break-all;
}

.location-dt {
  text-align: left;
}

.bar-divider {
  margin-bottom: 0 !important;
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.location-lt {
  padding-left: 2em;
}

.bar-chart-detailed-con {
  background-color: #eff3ff;
  border-bottom: 1px #eae5e5 solid;
  height: 3rem;
}

.mr-2 {
  margin-right: 1rem;
}

.view-bottn {
  width: 6%;
}

.view-btn {
  width: 49px;
  height: 2rem;
  padding-top: 0.2rem;
}

.view-btm-con {
  width: 8%;
}

.gap-closure-header {
  margin-top: 1rem;
}

.action-item-row {
  margin-top: 3rem;
  border-radius: 6px;
}

.gap-closure-header-sub-con {
  width: 50%;
}

.gap-closure-header-action {
  flex-direction: row-reverse;
  margin-top: -1.1rem;
}

.table-view {
  border: 1px #274ba7 solid;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0px 50px 50px 0px;
  width: 40px;
  color: #274ba7;
  cursor: pointer;
}

.grap-view {
  border: 1px #274ba7 solid;
  height: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px 0px 0px 50px;
  width: 40px;
  color: #274ba7;
  cursor: pointer;
}

.active-view {
  background-color: #274ba7;
  color: #fff !important;
}

.print-view {
  margin-right: 1em;
  font-size: 18px;
}

.download-view {
  margin-right: 0.5em;
  font-size: 18px;
}

.download-view:hover,
.print-view:hover {
  background: #e3e8f7;
}

.action-btn {
  border: 1px #274ba7 solid;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  cursor: pointer;
}

.gp-hr {
  margin-top: -1.2rem !important;
}

.on-tran-tick {
  background: #109b10;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}

.off-tran-tick {
  background: #d35757;
  color: #fff;
  border-radius: 50px;
  font-size: 16px;
}

.badge-cl {
  background-color: transparent !important;
}

.pillar-st {
  width: 5rem;
  height: 5rem;
  border-radius: 50px;
  text-align: center;
  line-height: 61px;
  color: #fff;
}

.on-pillar {
  background: #3a5824;
  /* background: #24693d;3a5824 */
}

.off-pillar {
  background: #c11a3b;
}

.non-pillar {
  background: #7c7c7c;
  opacity: 0.2;
}

.pillar-con {
  padding-left: 4em;
  padding-right: 4em;
  margin-top: 3em;
}

.pillar-tic {
  margin-top: -65px;
  margin-left: 38px;
}

.track-tic-con {
  text-align: center;
  margin-top: 1em;
}

.mr-off-tic {
  margin-left: 2em;
}

.team-bar-sm {
  display: none;
}

@media only screen and (max-width: 992px) {
  .pillar-tm {
    margin-top: 10px;
  }

  .education-row {
    margin-top: 1.5em;
  }

  .pagination-align {
    justify-content: left !important;
  }

  .team-learninf-botm {
    display: block !important;
  }

  .view-team-learning {
    width: 100% !important;
  }

  .pie-chart-con {
    width: 100%;
  }

  .bar-slice-info {
    display: none;
  }

  .team-learning-chart {
    width: 100% !important;
  }

  .team-bar-sm {
    display: flex;
  }
}

.acti-item-filter {
  /* width: 68%; */
  display: flex;
  margin-top: -5px;
  flex-direction: row-reverse;
  margin-left: 10%;
  position: absolute;
  right: 10px;
  top: 45px;
}

.funnel-con {
  font-size: 16px;
}

.form-check>input:checked {
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-image: url(../../assets/green-check.svg);
}

@media only screen and (max-width: 767px) {
  .per-page-cont {
    text-align: left;
  }
}

@media only screen and (max-width: 576px) {
  .gap-closure-header {
    display: block !important;
  }

  .gap-closure-header-action {
    width: 100% !important;
  }

  .gp-hr {
    margin-top: 0px !important;
  }
}

.custome-con {
  width: 50%;
  text-align: right;
}

.tab-nav {
  font-size: 0.838em;
  margin-top: 1em;
  margin-bottom: 10px;
}

.active-tab {
  font-weight: 600;
  color: #234372;
}

.active-tab-scroll-hr {
  border-top: 3px #274ba7 solid;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  opacity: 1 !important;
  margin-top: 1rem;
}

.opacity-zero {
  opacity: 0;
}

.education-tab {
  margin-top: 3rem;
}

.sub-menu {
  padding-left: 4.5em;
  padding-right: 4.5em;
  padding-top: 2rem;
  z-index: 99;
  margin-top: -48px;
  background: #e5e6e8;
  position: sticky;
  border-radius: 25px;
  top: 60px;
}

.sub-menu-devider {
  margin-top: -2rem;
}

.sub-con {
  height: 3em;
  background-color: #274ba7;
  position: sticky;
  top: 60px;
}

.cource-act {
  align-items: center;
  margin-top: 3rem;
}

.detial-icon {
  font-size: 9px;
  margin-left: 2px;
}

.see-all-detais {
  margin-top: 3em;
  text-align: right;
}

.edu-col {
  margin-top: 2rem;
}

.from-dt {
  opacity: 0.7;
}

.gc-table {
  margin-top: 3em;
}

.my-tm-togg-con {
  border-radius: 50px;
  cursor: pointer;
  height: 21px;
  width: 22px;
  text-align: center;
  background-color: #eff3ff !important;
  border: 1px solid #274ba7 !important;
  display: flex;
  padding-left: 0.3rem;
  margin-right: 2rem;
}

.my-tm-togg-con:hover {
  background-color: #274ba727 !important;
}

.my-tm-togg-img {
  width: 0.9rem;
}

.po-action-items-tl {
  padding-bottom: 2rem;
}

.mt-1 {
  margin-top: 1rem !important;
}
</style>

